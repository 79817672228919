$(function () {
	$("#Category").change(function () {
		var selectedCategory = $(this).val();
		if (selectedCategory == "")
			selectedCategory = urlPrefix;

		window.location.href = baseUrl + selectedCategory;
	});

	$("#search-box").keydown(function (e) {
		var key = e.charCode || e.keyCode || 0;
		if (key == 13)
			$("#search-go").click();
	});

	$("#search-go").click(function () {
		var selectedCategory = $("#Category").val();
		if (selectedCategory == "")
			selectedCategory = urlPrefix;
		var searchText = $("#search-box").val();
		var goToUrl = baseUrl + selectedCategory + (searchText != "" ? "?SearchText=" + encodeURIComponent(searchText) : "");
		if (window.location.pathname + window.location.search != goToUrl)
			window.location.href = goToUrl;
	});
});